"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
require("../styles/navbar.css");
const Navbar = () => {
    const [darkMode, setDarkMode] = (0, react_1.useState)(false);
    const [menuOpen, setMenuOpen] = (0, react_1.useState)(false);
    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
        console.log('Dark mode toggled:', !darkMode);
    };
    const toggleMenu = () => {
        setMenuOpen(prevState => !prevState);
    };
    return ((0, jsx_runtime_1.jsxs)("div", { className: `navbar ${darkMode ? 'dark' : ''}`, children: [(0, jsx_runtime_1.jsx)("div", { className: `logo`, onClick: toggleMenu, children: (0, jsx_runtime_1.jsxs)(react_router_dom_1.Link, { to: "/", className: "logo-link", style: { textDecoration: 'none', color: 'black' }, onClick: toggleMenu, children: ["\uD83C\uDF5C", (0, jsx_runtime_1.jsx)("span", { style: { fontSize: '.8em' }, children: "&" }), "\uD83E\uDDC7"] }) }), (0, jsx_runtime_1.jsx)("div", { className: `menu-icon ${menuOpen ? 'open' : ''}`, onClick: toggleMenu, children: (0, jsx_runtime_1.jsx)("i", { className: `fas ${menuOpen ? 'fa-times' : 'fa-bars'}` }) }), (0, jsx_runtime_1.jsxs)("div", { className: `nav-links ${menuOpen ? 'open' : ''}`, children: [(0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/", onClick: toggleMenu, children: "Home" }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/plan", onClick: toggleMenu, children: "Plan" }), (0, jsx_runtime_1.jsx)(react_router_dom_1.Link, { to: "/photos", onClick: toggleMenu, children: "Photos" })] })] }));
};
exports.default = Navbar;
