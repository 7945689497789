"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
require("../styles/home.css");
const themeContext_1 = require("../components/themeContext");
const Home = () => {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const { darkMode } = (0, themeContext_1.useTheme)();
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)("div", { className: `intro ${darkMode ? 'dark' : ''}`, children: [(0, jsx_runtime_1.jsx)("p", { children: "We are currently taking a break to do our own trips" }), (0, jsx_runtime_1.jsx)("p", { children: "Trip restarts on the 29th in LA! Then the west coast" })] }) }));
};
exports.default = Home;
