"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeProvider = exports.useTheme = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ThemeContext = (0, react_1.createContext)(undefined);
const useTheme = () => {
    const context = (0, react_1.useContext)(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};
exports.useTheme = useTheme;
const ThemeProvider = ({ children }) => {
    const [darkMode, setDarkMode] = (0, react_1.useState)(false);
    const toggleDarkMode = () => {
        setDarkMode((prev) => {
            console.log('Dark mode toggled:', !prev);
            return !prev;
        });
    };
    console.log('Dark mode state:', darkMode);
    return ((0, jsx_runtime_1.jsx)(ThemeContext.Provider, { value: { darkMode, toggleDarkMode }, children: children }));
};
exports.ThemeProvider = ThemeProvider;
